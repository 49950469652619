import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import withPageLabels from '../../hocs/withPageLabels';
import { connectTo } from "../../utils/generic";
import * as actions from "../../actions/forgotPassword";

import Link from "../../components/Link";
import EmailInput from "../../components/EmailInput";
import CustomButton from "../../components/customButton/customButton";
import * as colors from "../../theme/colors";

import Footer from "../../containers/Footer/footer";

import { useTitle } from "../../utils/pageTitles";

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: 25,
  },
  gradientBox: {
    height: "100%",
  },
  container: {
    width: "100%",
    zIndex: 1,
  },
  content: {
    //backgroundImage: `url(${PatternImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0 -85px",
    backgroundSize: "100% 508px",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 135px)",
    [theme.breakpoints.down('xs')]: {
      minHeight: "calc(100vh - 197px)",
    }
  },
  card: {
    maxWidth: 445,
    width: "100%",
    margin: 20,
  },
  cardContent: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: "0px !important",
  },
  boxContent: {
    margin: '20px 0px',
    textAlign: "center",
  },
  form: {
    width: "100%",
  },
  login: {
    margin: "35px auto 0px auto",
  },
  title: {
    fontSize: 22,
    fontWeight: "bold",
    color: colors.darkThemeBlueGray,
    maxWidth: 350,
    margin: "35px auto",
  },
  subTitle: {
    fontSize: 14,
    lineHeight: 1.75,
    fontFamily: "'Roboto Mono', monospace",
    padding: '0px 25px',
  },
  cantFindIt: {
    fontSize: 16,
    marginTop: 40,
    lineHeight: 1.75,
  },
  createAccountBox: {
    marginTop: 32,
  },
  smallText: {
    color: "#565656",
    fontSize: 12,
  },
  submitContainer: {
    marginTop: 56,
    marginBottom: 16,
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    padding: '0px 24px',
  },
  blockSpacer: {
    color: "#20a8ed",
    margin: "0 11px",
  },
  helpLink: {
    color: colors.darkThemeGreen,
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "initial",
    marginTop: 30,
    "&:hover": {
      cursor: "pointer",
    },
  },
  submitButton: {
    height: 42,
    width: "95%",
  },
  bottomLinksBox: {
    backgroundColor: colors.darkGreenBackground,
    bottom: 0,
    left: 0,
    width: "100%",
    display: "inline-flex",
    justifyContent: "space-between",
    height: 58,
    alignItems: "center",
  },
  bottomLink: {
    color: colors.white,
    marginLeft: 10,
    fontSize: 12,
    "&:hover": {
      color: colors.lightThemeGreen,
      textDecoration: "none",
    },
  },
  gwc: {
    marginLeft: 20,
    color: colors.white,
  },
  buttonContainer: {
    margin: "auto",
  },
  builtBy: {
    color: colors.white,
    fontSize: 12,
    marginLeft: 25,
    marginRight: 20,
    marginTop: 20,
    paddingBottom: 20,
  },
  bottomLinksContainer: {
    display: "inline-flex",
    alignItems: 'center',
  },
  noteMsgContainer: {
    backgroundColor: colors.orange75,
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: 55,
  },
  noteMsgLabel: {
    marginLeft: 10,
    color: colors.darkThemeBlueGray,
    lineHeight: '24px',
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "'Roboto Mono', monospace",
  },
  backLoginBtn: {
    fontWeight: 700,
    fontSize: 16,
    color: colors.teal2,
  },
  btnBox: {
    display: 'flex',
    width: '100%',
    padding: 20,
  },
  email: {
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: () => `1px solid ${colors.lightThemeGreen}`,
      },
    }
  },
  infoLink: {
    color: colors.darkThemeGreen,
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "initial",
    cursor: "pointer",
    lineHeight: 1.75,
    marginTop: 10,
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

const validationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .email("Invalid email address")
    .required("Required"),
});

const renderEmail = (email) => {
  if (!email || email.length === 0) return;

  let result = `${email[0]}${email[1]}`; // first 2 symbols

  const beforeAt = email.split("@")[0];

  // adding stars
  if (beforeAt.length > 4) {
    for (let index = 0; index < beforeAt.length - 4; index++) {
      result += "*";
    }
  }

  result += beforeAt[beforeAt.length - 2] + beforeAt[beforeAt.length - 1]; // last 2 symbols
  result += "@";

  const domain = email.split("@")[1].split(".")[0];

  result += domain[0];

  // adding stars
  if (domain.length > 2) {
    for (let index = 0; index < domain.length - 2; index++) {
      result += "*";
    }
  }

  result += domain[domain.length - 1] + "."; // last domain symbol

  result += email.split("@")[1].split(".")[1];

  return result;
};

const ForgotPassword = ({
  disableResend,
  sendRequest,
  showResultScreen,
  location,
  email,
  contentful,
  pageLabels,
  history: { push },
}) => {
  const [helpLink, setHelpLink] = useState(null);
  useTitle("forgot-password");

  const classes = useStyles();

  useEffect(() => {
    if (!contentful.GlobalComponentQuery) {
      return;
    }

    const {
      GlobalComponentQuery: {
        header,
      }
    } = contentful;

    setHelpLink(header.navBarHelpLink);
  }, [contentful]);

  const content = pageLabels ? pageLabels.modules[0] : null;
  const showNoteMsg = location.state && location.state.showNote ? location.state.showNote : false;
  if (!content) return null;

  return (
    <Box className={classes.gradientBox}>
      <Box className={classes.content}>
        {!!showNoteMsg && <Box className={classes.noteMsgContainer}>
        <img src={content.expiredTokenIconCta.icon.src} alt="expired token" />
          <Typography className={classes.noteMsgLabel}>
            {content.expiredTokenMessage}
          </Typography>
        </Box>}
        <Card className={classes.card}>
          {showResultScreen && (
            <CardContent className={classes.cardContent}>
              <Box className={classes.boxContent}>
                <Typography
                  variant="h4"
                  className={classes.title}
                  color="primary"
                  gutterBottom
                >
                  {content.headerSucessScreen}
                </Typography>
                <Typography
                  variant="h6"
                  color="primary"
                  className={classes.subTitle}
                >
                  {content.subheaderSuccessScreen}
                </Typography>
                <Typography
                  variant="h6"
                  color="primary"
                  className={classes.subTitle}
                >
                  {renderEmail(email)}
                </Typography>
                <Typography
                  variant="h6"
                  color="primary"
                  className={classes.cantFindIt}
                >
                  {content.canNotFindLabel}
                </Typography>
                <Link
                  className={classes.helpLink}
                  onClick={() => {
                    if (helpLink) {
                      window.open(helpLink);
                    }
                  }}
                >
                  {content.goBackLink}
                </Link>
              </Box>
              <Box className={classes.submitContainer}>
                <CustomButton
                  mode={"primary"}
                  type="submit"
                  style={{ height: 42 }}
                  disabled={disableResend}
                  onClick={() => {
                    sendRequest({ email, resend: true });
                  }}
                  label={content.resendButtonText}
                />
              </Box>
            </CardContent>
          )}
          {!showResultScreen && (
            <>
              <CardContent className={classes.cardContent}>
                <Box>
                  <Box className={classes.boxContent}>
                    <Typography
                      variant="h4"
                      className={classes.title}
                      color="primary"
                      gutterBottom
                    >
                      {content.header}
                    </Typography>
                    <Typography
                      variant="h6"
                      color="primary"
                      className={classes.subTitle}
                    >
                      {content.subheader}
                    </Typography>
                  </Box>
                  <Box>
                    <Formik
                      initialValues={{
                        email: "",
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values) => {
                        sendRequest(values);
                      }}
                    >
                      <Form className={classes.form}>
                        <Container className={classes.container}>                          
                          <Box className={classes.login}>
                            <Field
                              id="email"
                              name="email"
                              component={EmailInput}
                              helperText={content.inputHelperText}
                              label={content.inputLabel}
                              className={classes.email}
                              required={true}
                            />
                          </Box>
                        </Container>
                        <Box className={classes.submitContainer}>
                          <CustomButton
                            className={classes.submitButton}
                            mode={"primary"}
                            type="submit"
                            label={content.submitButtonText}
                            sel="resendEmail-button"
                          />
                        </Box>
                      </Form>
                    </Formik>
                  </Box>
                </Box>
                <Box className={classes.btnBox}>
                  <Link className={classes.infoLink} onClick={() => push(`/login`)}>
                    <ArrowBackIcon /> Back to Login
                  </Link>
                </Box>
              </CardContent>
            </>
          )}
        </Card>
      </Box>
      <Footer />
    </Box>
  );
};

export default connectTo(
  (state) => {
    return {
      ...state.forgotPassword,
      contentful: state.contentful,
    }
  },
  actions,
  withPageLabels({ slug: "/forgot-password" }, ForgotPassword)
);
