import React, { useState } from 'react';
import { Form, Field, useFormikContext } from 'formik';
import * as moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Box from '@material-ui/core/Box';

import { DOMESTIC_ID } from '../../../utils/countriesDict';
import { getIsFormInvalid } from '../../../api/users';
import CheckBox from '../../../components/CheckBox';
import CustomButton from "../../../components/customButton/customButton";
import TextCustomButton from "../../../components/text-button/text-button";
import DividerWithText from '../../../components/DividerWithText';
import GoogleButton from '../../../components/GoogleButton';
import ClubStep from './ClubStep';
import UserInfoStep from './UserInfoStep';
import Step0 from './Step0';

import { createMarkup, filterField, filterFieldsByKey } from '../../../utils/generic';
import * as colors from '../../../theme/colors';
const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    marginTop: 10,
    maxWidth: 465,
  },
  submitButton: {
    marginBottom: 20,
    marginTop: 30,
    width: "100%",
    height: 42,
  },
  backButton: {
    fontSize: 14,
    margin: "0 0 20px 0",
    "& .MuiButton-startIcon": {
      marginRight: 0,
    },
    "&:focus": {
      outline: `2px dotted ${colors.grey400}`,
    },
  },
  googleButton: {
    fontWeight: 'bold',
    height: 56,
    width: '100%',
  },
  ssoDivider: {
    marginTop: 20,
    marginBottom: 20,
  },
  errorMsg: {
    color: colors.redA400,
    fontSize: 12,
    marginBottom: 12,
  },
  parentMsg: {
    fontSize: 14,
    lineHeight: 1.4,
    padding: '0 35px',
    margin: '15px 0',
    color: colors.darkThemeBlueGray,
    textAlign: 'center',
    '& > span': {
      fontWeight: 700,
    },
  },
}));

const getCurrentPage = ({ 
  page, signUpContent, 
  invitedEmail, 
  setPage, clubCode, checkingByCodeResult, isClubCodeValidating, setErrorByClubCode, cleanCheckClubByCode, 
  setIs1StepValid }) => {
 switch (page) {
   case 0:
     return <Step0 signUpContent={signUpContent} setIsValidStep={setIs1StepValid} />
   case 1:
     return <UserInfoStep signUpContent={signUpContent} invitedEmail={invitedEmail} />
   case 2:
     return <ClubStep
       signUpContent={signUpContent}
       checkingByCodeResult={checkingByCodeResult}
       setPage={setPage}
       clubCode={clubCode}
       isClubCodeValidating={isClubCodeValidating}
       setErrorByClubCode={setErrorByClubCode}
       cleanCheckClubByCode={cleanCheckClubByCode}
     />
   default:
     return <Step0 signUpContent={signUpContent} setIsValidStep={setIs1StepValid} />
 }
};

const SignUpForm = ({ register, page, errors, onBack, signUpContent, isSubmitting, invitedEmail,
  checkingByCodeResult, setPage, clubCode, isClubCodeValidating, setSubmittingForm, cleanCheckClubByCode, setErrorByClubCode, isErrorByClubCode,
  qrClubCode, isParent }) => {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [is1StepValid, setIs1StepValid] = useState(false);

  let emailContent = filterField(signUpContent.signupFields, "id", "signupEmail");

  const { values } = useFormikContext();

  const notValid = page === 0 && 
    (Object.keys(errors).length > 0 && (errors.country || errors.birthDate || errors.role || errors.termsAndConditions) 
    || !is1StepValid)
    || isErrorByClubCode;

  React.useEffect(() => {
    if (error) {
      setError(false);
    }
    if (page === 1) setSubmittingForm(false);

    document.getElementById("signUpTab").focus()
  }, [page])

  const responseGoogle = async (response) => {
    const { accessToken, profileObj } = response;
    const validationErrors = filterFieldsByKey(emailContent.validationErrors, "messageCode", "message");

    if (!profileObj) return;

    const { email, family_name: lastName, given_name: firstName } = profileObj;
    
    const { data: isInvalid } = await getIsFormInvalid({
      firstName,
      lastName,
      email,
    });

    if (isInvalid) {
      setError(validationErrors.invalidEmail);
      return;
    }

    const submittedValues = {
      ...values,
      accessToken,
      firstName,
      lastName,
      email,
      password: '',
      qrClubCode
    };

    register(submittedValues);
  }

  let now = moment();
  let dobDate = moment.parseZone(values.birthDate).format();
  const age = now.diff(dobDate, 'year');

  const chooseRegisterButtonLabel = (page) => {
    switch(page) {
      case 0:
        return 'Sign up'
      case 1:
        return 'Complete your account'
      case 2:
        return 'Continue'
      default:
        return '';
    }
  };

  return (
    <Form className={classes.container}>
      <Container>
        {page === 1 && age >= 13 &&
          <>
            {values.country !== DOMESTIC_ID && <Field
              name="agreeCommunicate"
              component={CheckBox}
              label={signUpContent.communicationConsent}
              validate={(value) => value ? null : 'Required'}
              required
            />}
            {error && !!error.length && <Typography role='alert' aria-label={`alert ${error.replaceAll(/<\/?p[^>]*>/g, '')}`} className={classes.errorMsg} dangerouslySetInnerHTML={createMarkup(error)}/>}
            {isParent && <Box className={classes.parentMsg} role="region" aria-labelledby="create-parent-account register-child-later"><span id="create-parent-account">First, create your parent/guardian account!</span> <div id="register-child-later">You will register your child’s account in a later step</div>.</Box>}
              <GoogleButton
                onSuccess={responseGoogle}
                onError={responseGoogle}
                disabled={values.country !== DOMESTIC_ID && !values.agreeCommunicate}
                variant="outlined"
                labelText="Sign up with Google"
              />
            <DividerWithText className={classes.ssoDivider}>OR</DividerWithText>
          </>
        }

        {isSubmitting
          ? <div style={{ marginLeft: '40%' }}><CircularProgress size={70} /></div>
          : getCurrentPage({
            page, 
            signUpContent,
            invitedEmail,
            setPage,
            clubCode,
            checkingByCodeResult,
            isClubCodeValidating,
            setErrorByClubCode,
            cleanCheckClubByCode,
            setIs1StepValid
          })}
        {/* Due to ticket  HQ-4127 on the step 0 we have 'Sing up' title. On the step 1 we have 'Complete your account */}
        <CustomButton
          className={classes.submitButton}
          disabled={notValid || !!isSubmitting}
          mode={"primary"}
          type="submit"
          label={chooseRegisterButtonLabel(page, age)}
        />
        {(page === 1 || page === 2) && !isSubmitting &&
          <TextCustomButton
            className={classes.backButton}
            startIcon={<ArrowBackIosIcon />}
            mode="secondary"
            onClick={onBack}
            label={signUpContent.signupAllStepsBackLabel}
          />
        }
      </Container>
    </Form>
  );
};

export default SignUpForm;
