import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import { HQModal } from "../HQModal";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import moment from "moment";
import CustomButton from "../../../components/customButton/customButton";
import DateInput from "../../date-input/DateInput";
import { PageLeaveModal } from '../../../components/modals/pageLeaveWarning';
import * as actions from "../../../actions/userProfile";
import { DATE_FORMAT } from "../../../utils/constants";
import { connectTo } from "../../../utils/generic";
import { DOMESTIC_ID } from "../../../utils/countriesDict";


const useStyles = makeStyles(theme => ({
  subTitle: {
    color: theme.palette.primary.main,
    fontSize: 16,
    lineHeight: 1.75,
    textAlign: "center"
  },
  marginTop30: {
    marginTop: "30px",
  },
  buttonGroup: {
    marginTop: "30px",
    height: 42,
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    boxShadow: "none",
  },
}));

const UserDOBModal = ({ onClose, userDOBModal, user, changeUserInfo, domesticCountry, callback, message }) => {
  const classes = useStyles();
  const [isEscapeConfirmDialog, setEscapeConfirmDialog] = useState(false);
  const [isDataChanged, setDataChanged] = useState(false);

  return (
     <HQModal title='Update Your Birthdate' isOpen={userDOBModal} onClose={() => isDataChanged ? setEscapeConfirmDialog(true) : onClose() }>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h4" className={classes.subTitle}>
          Please update your Birthdate to make further edits.
        </Typography>    
        <Formik
          initialValues={{
            birthDate: user.birthDate,
          }}          
          validationSchema={Yup.object({
            birthDate: Yup.date()
              .max(new Date(), "Date of Birth cannot be the future date")
              .min((moment().subtract(100, 'years')), "Please check the format of your date of birth.")
              .required("Required")
              .typeError("Required")
          })}
          validate={() => {
            setDataChanged(true);
            return ;
          }}
          onSubmit={({ birthDate }) => {
            if (birthDate) {
              changeUserInfo({
                data: {
                  birthDate: moment.parseZone(birthDate).format(DATE_FORMAT)
                },
                callback,
                message
              });
            }
            onClose();
          }}
        >
          <Form noValidate>  
            <Field
              name="birthDate"
              component={DateInput}
              label="Birthdate"
              maxDate={new Date()}
              className={classes.marginTop30}
              domesticId={domesticCountry}
              countryId={user.countryId}
              helperText="So we can provide a custom experience for you."
              required={true}
            />

            <div variant="contained" className={classes.buttonGroup}>
              <CustomButton
                mode="textButton"
                onClick={() => isDataChanged ? setEscapeConfirmDialog(true) : onClose() }
                label={"Cancel"}
              />
              <CustomButton
                mode="primary"
                type="submit"
                label={"Save"}
              />
            </div>
          </Form>
        </Formik>
      </DialogContent>
      <PageLeaveModal
        onConfirm={() => { setDataChanged(false); setEscapeConfirmDialog(false); onClose(); }}
        onCancel={() => { setEscapeConfirmDialog(false); }}
        open={isEscapeConfirmDialog}
      />
       
    </HQModal>
  );
};

export default connectTo(
  (state) => {
    return {
      user: state.auth.user,
      domesticCountry: parseInt(state?.contentful?.hqConfig?.domesticCountryId ?? DOMESTIC_ID, 10)
    };
  },
  {
    ...actions,
  },
  UserDOBModal
);
