import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import * as colors from "../../../theme/colors";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import ListItemText from "@material-ui/core/ListItemText";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Linkify from 'react-linkify';

import TextButton from "../../../components/text-button/text-button";
import { ClubVirtualLinkModal } from "../../../components/modals/clubProfile/clubVirtualLinkModal";
import { ShippingAddressModal } from "../../../components/modals/clubProfile/shippingAddressModal";
import { СlubSocialMediaModal } from "../../../components/modals/clubProfile/clubSocialMediaModal";
import { PartnerAffiliationModal } from "../../../components/modals/clubProfile/partnerAffiliationModal";
import CopyLinkButton from '../../../components/copyLinkButton/copyLinkButton';
import { PROGRAM_TYPES } from "../../../utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "8px 8px 12px 8px",
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  regularLink: {
    "&:hover": {
      textDecoration: "none",
    },
    fontWeight: 600,
  },
  redLink: {
    color: colors.red300,
    "&:hover": {
      textDecoration: "none",
    },
    fontWeight: 600,
  },
  cardHeader: {
    padding: 0,

    [theme.breakpoints.down("xs")]: {
      wordBreak: "break-all",
    },
    "& .MuiCardHeader-action": {
      margin: 0,
      "align-self": "auto",
    },
  },
  cardContent: {
    padding: "0 0 0 4px",
    "&:last-child": {
      paddingBottom: 0,

      [theme.breakpoints.down("xs")]: {
        wordBreak: "break-all",
      },
    },
  },
  lastLogin: {
    marginTop: 8,
    fontFamily: "'Roboto Mono',monospace",
    "& b": {
      fontFamily: "'Roboto', sans-serif",
    },
  },
  item: {
    display: "flex",
    marginBottom: 20,
    "& .MuiTypography-body1": {
      width: "35%",
      margin: "auto 0",
      fontWeight: "bold",
    },
    "& .MuiTypography-body2": {
      width: "60%",
      margin: "auto 0",
      fontSize: '1rem',
      color: colors.dartThemeBlueGray,

      "& button": {
        textAlign: 'left'
      }
    },
  },
  link: {
    color: colors.darkThemeGreen,
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "initial",
    textDecoration: "none",
    lineHeight: 1.75,
    marginTop: 10,
    wordBreak: 'break-all',
    "&:hover": {
      textDecoration: "none",
      color: colors.lightThemeGreen,
    },
  },
  addLinkBtn: {
    justifyContent: "start",
    paddingLeft: 0,
    paddingRight: 0,
    '&:hover': {
      color: colors.lightThemeGreen,
    },
  },
  programCode: {
    color: (props) => props.programColor,
    fontWeight: 600,
  },
  copyBtn: {
    fontSize: 12,
    fontWeight: "normal",
    cursor: "pointer",
  },
}));

const getDecisionMaker = (club) => {
  let result = "";

  if (!club || !club.decisionMaker) {
    return result;
  }

  const { fullName, email } = club.decisionMaker;
  if (fullName) {
    result = fullName;
  }

  if (email) {
    result = `${result} ${email}`;
  }

  return result;
};

const typeOptions = [
  { value: "Virtual sessions", label: "Virtual" },
  { value: "In-person sessions", label: "In-Person" },
  { value: "A combination of in-person and virtual sessions", label: "Hybrid" },
];

const BasicInfoCard = ({
  club,
  saveShippingAddress,
  saveVirtualLink,
  saveSocialMedia,
  saveCommunityPartnerAffilation,
  isEditable,
  communityPartners,
  labels,
  rolePermissions,
  loadPartnerships,
  programTypeDetails,
}) => {
  const {
    backgroundColor,
    shippingAddressEnabled,
    classroomEnabled,
    decisionMakerEnabled,
    timezoneEnabled,
    virtualConferenceLinkEnabled,
    organizationAddressEnabled,
    socialMediaEnabled,
    communityPartnerAffiliationEnabled,
    meetingTypeEnabled,
    programType
  } = programTypeDetails;
  const programColor = programType === PROGRAM_TYPES.COLLEGE_LOOP ? colors.mainThemeBlack : backgroundColor;
  const classes = useStyles({ programColor });

  const [isScheduleOpen, setIsScheduleOpen] = React.useState(false);
  const [isShippingOpen, setIsShippingOpen] = React.useState(false);
  const [socialMediaOpen, setSocialMediaOpen] = React.useState(false);
  const [isCPAffilationOpen, setIsCPAffilationOpen] = React.useState(false);

  const decisionMakerInfo = getDecisionMaker(club);

  let shippingAddressItems = [
    club.shippingStreet,
    club.shippingCity,
    club.shippingState,
    club.shippingZipCode,
  ];

  const shippingAddress =
    shippingAddressItems.length > 0
      ? shippingAddressItems.filter((e) => e).join(", ")
      : "";

  const clubTypeItem = typeOptions.find(e => e.value === club.clubMeetingType);
  const meetingTypeLabel = clubTypeItem ? clubTypeItem.label : "";

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer" className={classes.link}>
      {text}
    </a>
  );

  return (
    <Card className={classes.root}>
      <CardHeader
        action={
          <Typography className={classes.programCode}>{club.code}</Typography>
        }
        title={<Typography className={classes.title}>Basic Info</Typography>}
        className={classes.cardHeader}
      />
      <CardContent className={classes.cardContent}>
        {meetingTypeEnabled && club.clubMeetingType && (
          <ListItemText
            primary="Meeting Type:"
            className={classes.item}
            secondary={meetingTypeLabel}
          />
        )}
        {virtualConferenceLinkEnabled && <ListItemText
          primary="Virtual Conference Link:"
          className={classes.item}
          secondary={
            club.virtualConferenceLink ? (
              <>
                <Link
                  className={classes.link}
                  target="_blank"
                  href={club.virtualConferenceLink}
                >
                  {club.virtualConferenceLink}
                </Link>{" "}
              </>
            ) : (
              <>
                {isEditable ? (
                  <TextButton
                    className={classes.addLinkBtn}
                    onClick={() => {
                      setIsScheduleOpen(true);
                    }}
                  >
                    Add link
                  </TextButton>
                ) : (
                  ""
                )}
              </>
            )
          }
        />}
        {organizationAddressEnabled && club.organizationAddress && (
          <ListItemText
            primary="Organization Address:"
            className={classes.item}
            secondary={club.organizationAddress}
          />
        )}
        {decisionMakerEnabled && decisionMakerInfo && (
          <ListItemText
            primary="Decision Maker:"
            className={classes.item}
            secondary={decisionMakerInfo}
          />
        )}
        {rolePermissions.name === 'Facilitator' && club.programImplementationSpecialistName && club.programImplementationSpecialistEmail && (
            <ListItemText
              primary="Program Implementation Specialist:"
              className={classes.item}
              secondary={
                <>
                  {`${club.programImplementationSpecialistName} `}
                  <CopyLinkButton link={club.programImplementationSpecialistEmail} />
                </>
              }

            />
        )}
        {classroomEnabled && club.sfClassroom && (
          <ListItemText
            primary="Classroom:"
            className={classes.item}
            secondary={club.sfClassroom}
          />
        )}
        {timezoneEnabled && club.timezone && (
          <ListItemText
            primary="Timezone:"
            className={classes.item}
            secondary={club.timezone}
          />
        )}
        {shippingAddressEnabled && (
          <ListItemText
            primary="Shipping Address:"
            className={classes.item}
            secondary={
              shippingAddress && shippingAddress.length ? (
                shippingAddress
              ) : (
                <>
                  {isEditable ? (
                    <TextButton
                      className={classes.addLinkBtn}
                      onClick={() => {
                        setIsShippingOpen(true);
                      }}
                    >
                      Add shipping address
                    </TextButton>
                  ) : (
                    ""
                  )}
                </>
              )
            }
          />
        )}
        {socialMediaEnabled && (
          <ListItemText
            primary="Social Media:"
            className={classes.item}
            secondary={
              club.socialMedia ? (
                <>
                  <Linkify componentDecorator={componentDecorator}>{club.socialMedia}</Linkify>
                  {" "}
                </>
              ) : (
                <>
                  {isEditable ? (
                    <TextButton
                      className={classes.addLinkBtn}
                      onClick={() => {
                        setSocialMediaOpen(true);
                      }}
                    >
                      Add
                    </TextButton>
                  ) : (
                    ""
                  )}
                </>
              )
            }
          />
        )}
        {communityPartnerAffiliationEnabled && (
          <ListItemText
            primary="Community Partner Affiliation:"
            className={classes.item}
            secondary={
                club.communityPartnership
                ? (
                  club.communityPartnership.name
                ) : (
                  <>
                    {isEditable ? (
                      <TextButton
                        className={classes.addLinkBtn}
                        onClick={() => {
                          setIsCPAffilationOpen(true);
                          if (!communityPartners || !communityPartners.length) {
                            loadPartnerships();
                          }
                        }}
                      >
                        Add community partner
                      </TextButton>
                    ) : (
                      ""
                    )}
                  </>
                )
            }
          />
        )}
      </CardContent>
      <ClubVirtualLinkModal
        isOpen={isScheduleOpen}
        program={club}
        onSave={saveVirtualLink}
        onCloseModal={() => {
          setIsScheduleOpen(false);
        }}
      />
      <ShippingAddressModal
        isOpen={isShippingOpen}
        program={club}
        onSave={saveShippingAddress}
        onCloseModal={() => {
          setIsShippingOpen(false);
        }}
      />
      <СlubSocialMediaModal
        isOpen={socialMediaOpen}
        program={club}
        onSave={saveSocialMedia}
        onCloseModal={() => {
          setSocialMediaOpen(false);
        }}
      />
      <PartnerAffiliationModal
        isOpen={isCPAffilationOpen}
        program={club}
        labels={labels}
        onSave={saveCommunityPartnerAffilation}
        communityPartners={communityPartners}
        onCloseModal={() => {
          setIsCPAffilationOpen(false);
        }}
      />
    </Card>
  );
};

export default BasicInfoCard;
