import React from "react";

import * as moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";

import Selector from "../../../components/selector/Selector";
import Typography from "@material-ui/core/Typography";
import CheckboxGroup from "../../../components/checkboxGroup/checkBoxGroup";
import TextInput from "../../../components/TextInput";
import PhoneInput from "../../../components/PhoneInput";
import * as colors from "../../../theme/colors";

import { enrollmentTrackingEvent } from "../constants";
import EmailInput from "../../LoginSignUp/Register/EmailInput";
import DateInput from "../../../components/date-input/DateInput";
import TextCustomButton from "../../../components/text-button/text-button";
import CheckBox from '../../../components/CheckBox';
import { programTypes } from "../constants";
import clsx from 'clsx';
import { FocusError } from "../../../utils/generic";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  container: {
    maxWidth: 650,
    padding: '0 24px',
  },
  content: {
    display: "flex",
  },
  stepInfo: {
    textAlign: "center",
    margin: "-20px 0 20px 0",
    fontSize: 14,
    lineHeight: "1.5",
    color: colors.darkThemeBlueGray,
    fontWeight: 400,
  },
  subTitle: {
    margin: "0 0 20px 0",
    fontSize: 14,
    fontWeight: 700,
    lineHeight: "1.5",
    color: colors.darkBlue100,
  },
  note: {
    margin: "35px 0 10px",
    fontSize: 14,
    lineHeight: "1.5",
    color: theme.palette.primary.main,
  },
  answerLabel: {
    fontSize: 14,
    lineHeight: "1.5",
    fontWeight: 400,
    color: colors.darkThemeBlueGray,
  },
  item: {
    width: "100%",
  },
  previousEmail: {
    justifyContent: "flex-end",
    display: "inline-flex",
    width: "100%",
  },
  marginTop30: {
    marginTop: 10,
  },
  additionalButton: {
    color: colors.darkThemeGreen,
    fontSize: 14,
    cursor: "pointer",
    marginTop: -10,
  },
  verticalFieldBox: {
    display: "inline-grid",
    width: "45%",
  },
  raceFieldBox: {
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 10,
    '& .MuiIconButton-label': {
      color: colors.darkThemeGreen,
    },
    "& .Mui-checked": {
      color: colors.darkThemeGreen,
    },
    "& .MuiTypography-root": {
      "& .MuiBox-root": {
        fontSize: 14,
        fontWeight: 400,
        textAlign: "left",
      },
    },
    "& .MuiFormControl-root": {
      "& .MuiFormLabel-root": {
        color: colors.darkThemeBlueGray,
        fontSize: 14,
        fontWeight: 400,
      },
      "& .MuiFormGroup-root": {
        "& .MuiFormControlLabel-root": {
          "& .MuiTypography-body1" :{
            fontSize: 14,
            lineHeight: "1.5",
            fontWeight: 400,
            color: colors.darkThemeBlueGray,
          }
        }
      }
    }
  },
  fieldBox: {
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 10,
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        padding: "11px 16px",
        fontSize: 14,
        fontWeight: 400,
        color: colors.darkThemeBlueGray,
      }
    },
    "& .MuiTypography-root": {
      "& .MuiBox-root": {
        color: colors.lightGrey,
        fontSize: 12,
        fontWeight: 400,
        textAlign: "left",
      },
    },
    "& .MuiButtonBase-root": {
      color: colors.grey350,
    },
    "& .Mui-checked": {
      color: colors.darkThemeGreen,
    },
    "> div": {
      border: "1px solid #d8d8d8"
    },
    "& .MuiFormControl-root": {
      "& .MuiFormLabel-root": {
        "& .MuiFormLabel-asterisk": {
          color: colors.darkThemeBlueGray,
        }
      },
      "& .MuiFormGroup-root": {
        "& .MuiFormControlLabel-root": {
          "& .MuiTypography-body1" :{
            fontSize: 14,
            lineHeight: "1.5",
            fontWeight: 400,
            color: colors.darkThemeBlueGray,
          }
        }
      }
    },
    "& .MuiFormControl-root": { 
      "& .MuiInputLabel-outlined": {
        backgroundColor: colors.white,
      },
    },
    "& .MuiFormGroup-root": {
      "& .MuiFormControlLabel-root": {
        "& .MuiTypography-body1" :{
          fontSize: 14,
          lineHeight: "1.5",
          fontWeight: 400,
          color: colors.darkThemeBlueGray,
        }
      }
    },
    '& .MuiButtonBase-root': {
      color: colors.darkThemeGreen,
    },
  },
  lastNamefieldBox: {
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 20,
    "& .MuiFormControl-root": { 
      "& .MuiInputLabel-outlined": {
        color: colors.darkThemeBlueGray,
      },
    },
    "& .MuiFormControl-root": {
      "& .MuiInputBase-input": {
        padding: "11px 16px",
        fontSize: 14,
        fontWeight: 400,
        color: colors.darkThemeBlueGray,
      },
    },
  },
  submitContainer: {
    marginTop: 62,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  submitButton: {
    maxWidth: 350,
    marginBottom: 50,
    cursor: "pointer",
  },
  inputHeader: {
    lineHeight: "1.5",
    color: colors.lightGrey,
    textTransform: "uppercase",
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "Roboto Mono",
    marginBottom: 5,
  },
  graduationYearHeader: {
    margin: "20px 0 5px",
  },
  collegeMajorIdHeader: {
    margin: 0,
  },
  communicationOpportunities: {
    fontSize: 14,
    marginTop: 5,
  },
  participatedInGirlsWhoCodeProgram: {
    border: "none",
    "& .MuiButtonBase-root": {
      color: colors.grey350,
    },
    "& .Mui-checked": {
      color: colors.darkThemeGreen,
    },
    "& .MuiFormControl-root": {
      "& .MuiFormControlLabel-root": {
        "& .MuiFormControlLabel-label": {
          fontSize: 14,
          fontWeight: 400,
          lineHeight: 1.4,
          color: colors.darkBlue100,
        }
      },
    },
    "& .MuiFormLabel-root": {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.4,
      color: colors.darkBlue100,
    },
    "& div": {
      border: 0,
    }
  },
  showCommunicationsOpportunities: {
    marginLeft: 10,
    "& .MuiButtonBase-root": {
      color: colors.grey350,
    },
    "& .Mui-checked": {
      color: colors.darkThemeGreen,
    },
  }
}));

const participationItems = [
    { label: "Club", value: "Club" },
    { label: "Summer Immersion Programs", value: "Summer Immersion Program" },
    { label: "Campus", value: "Campus" },
  ];

const getInitialValues = (
  enrollmentData,
  isInternational,
  type,
  needBirthdate,
  needLastName,
) => {
  const data = {};
  if (type === programTypes.CLUB) {
    data.phoneNumber = enrollmentData.phoneNumber || "";
    data.graduationYear = "";
  }

  if (type !== programTypes.CLUB && type !== programTypes.COMMUNITY_PARTNERSHIP) {
    data.phoneNumber = enrollmentData.phoneNumber || "";
    data.socialProfileLinkedin = enrollmentData.socialProfileLinkedin || "";
    data.collegeGraduationYear = "";
    data.participatedInGirlsWhoCodeProgram =
      enrollmentData.participatedInGirlsWhoCodeProgram || "";
    data.collegeMajorId = "";
    data.raceEthnicity = enrollmentData.raceEthnicity || "";
    data.raceEthnicityAsian = enrollmentData.raceEthnicityAsian || "";
    data.raceEthnicityMultiracial =
      enrollmentData.raceEthnicityMultiracial || "";
  }

  if (needBirthdate) {
    data.birthDate = null;
  }

  if (needLastName) {
    data.lastName = null;
  }

  return data;
};

const checkDataChanges = (initialValues, data) => {
  return Object.keys(initialValues).some(field => {
    if (initialValues[field] !== data[field]) {
      return true;
    }
    return false;
  })
}

let values = null;

const ThirdStep = ({
  user,
  onChangeUserInfo,
  onSubmitEnrollment,
  type,
  enrollmentForm,
  locale,
  collegeMajors,
  isInternational,
  enrollmentData,
  domesticCountryId,
  createTrackingLog,
  needToUpdateFields,
  setDataChanged,
  isLoading,
  thirdStepRef,
  renderChildHeader,
  childID,
}) => {
  const classes = useStyles();

  const {
    additionalAsianEthnicityField,
    additionalMultiRacialEthnicityField,
    collegeGraduationYearField,
    ethnicityField,
  } = enrollmentForm;

  const asianItems = additionalAsianEthnicityField
    ? additionalAsianEthnicityField.options.map((e) => {
      return { label: e.label, value: e.value };
    })
    : [];

  const etnicities = ethnicityField ? ethnicityField.options : [];
  const multiRaceOptions = additionalMultiRacialEthnicityField
    ? additionalMultiRacialEthnicityField.options.map((e) => {
      return { label: e.label, value: e.value };
    })
    : [];

  const [showAsianEtnicity, setShowAsianEtnicity] = React.useState(null);
  const [showMultiEnticity, setShowMultiEnticity] = React.useState(null);

  const [showMinor, setShowMinor] = React.useState(false);
  const [showAdditionalMinor, setShowAdditionalMinor] = React.useState(false);
  const [showAdditionalMajor, setShowAdditionalMajor] = React.useState(false);
  const [showCommunicationOpportunities, setShowCommunicationOpportunities] = React.useState(false);

  let gradeSystem = locale ? locale.schoolGradeSystem : null;
  if (gradeSystem && gradeSystem.grades) {
    if (enrollmentForm.gradeField == null)
      enrollmentForm.gradeField = { options: {} };
    enrollmentForm.gradeField.options = gradeSystem.grades.map((grade) => ({
      name: grade.name,
      label: grade.name,
      value: grade.value,
    }));
  }
  const {
    raceEthnicity,
    raceEthnicityMultiracial,
    raceEthnicityAsian,
  } = enrollmentData;

  if (raceEthnicity) {
    // show items by default;
    if (raceEthnicity === "Multiracial" && showMultiEnticity === null) {
      setShowMultiEnticity(raceEthnicity === "Multiracial");
    }

    if (raceEthnicity === "Asian" && showAsianEtnicity === null) {
      setShowAsianEtnicity(raceEthnicity === "Asian");
    }
  }

  let defaultErrorRequired = "Required";

  let emailCorrectErrorMessage = "Must be a valid email";

  let schema = {};

  const { needLastName, needBirthdate } = needToUpdateFields;
  if (needLastName) {
    schema.lastName = Yup.string()
      .trim()
      .min(1, defaultErrorRequired)
      .max(20, "Must be 20 characters or less")
      .required(defaultErrorRequired)
      .typeError(defaultErrorRequired);
  }

  if (needBirthdate) {
    schema.birthDate = Yup.date()
      .max(new Date(), "Date of Birth cannot be the future date")
      .min((moment().subtract(100, 'years')), "Please check the format of your date of birth.")
      .required(defaultErrorRequired)
      .typeError(defaultErrorRequired);
  }


  if (type === programTypes.CLUB) {
    schema = {...schema, ...{
      phoneNumber: Yup.string().trim(),
      graduationYear: Yup.string().required(defaultErrorRequired),
      previousEmail: Yup.string().trim().email(emailCorrectErrorMessage),
    }};
  } else {

    if (!isInternational) {
      schema = {...schema, ...{
          raceEthnicity: Yup.string().required(defaultErrorRequired),
        }};
    }

    schema = {...schema, ...{
      collegeMajorId: Yup.string().required(defaultErrorRequired),
      collegeGraduationYear: Yup.string().required(defaultErrorRequired),
      previousEmail: Yup.string().trim().email(emailCorrectErrorMessage),
    }};
  }

  const programSchema = Yup.object(schema);

  React.useEffect(() => {
    return () => {
      if (!window.location.pathname.includes("join") && !isLoading) {
        createTrackingLog({
          event: enrollmentTrackingEvent.ENROLLMENT_THIRD_STEP_LEAVE,
          data: JSON.stringify(values),
        });
      }
    };
  }, []);
  const initialValues = getInitialValues(
    enrollmentData,
    isInternational,
    type,
    needBirthdate,
    needLastName,
    user,
    isLoading,
  ); 
  return (
    <Formik
      enableReinitialize={false}
      innerRef={(input) => {
        if (input) {
          values = input.values;
          thirdStepRef.current = input;
        }
      }}
      initialValues={initialValues}
      validate={(data) => {
        setShowCommunicationOpportunities(data.showCommunicationsOpportunities)
        const hasChanges = checkDataChanges(initialValues, data)
        setDataChanged(hasChanges);
        return;
      }}
      validationSchema={programSchema}
      onSubmit={(values) => {
        const payload = {};

        if (needBirthdate) {
          payload.birthDate = moment.parseZone(values.birthDate).format();
          delete values.birthDate;
        }

        if (needLastName) {
          payload.lastName = values.lastName;
          delete values.lastName;
        }

        if (needBirthdate || needLastName) {
          onChangeUserInfo(payload);
        }

        if (!values.collegeMajorId) {
          values.collegeMajorId = null;
        }

        if (!showMinor) {
          values.collegeMinorId = null;
        }

        if (!showAdditionalMajor) {
          values.collegeAdditionalMajorId = null;
        }

        if (!showAdditionalMinor) {
          values.collegeAdditionalMinorId = null;
        }

        onSubmitEnrollment(values);
      }}
    >
      <Form>
        <Typography className={classes.stepInfo}>
          {enrollmentForm.subheading}
        </Typography>
        {childID && renderChildHeader()}
        <Container className={classes.container}>
          {(needLastName || needBirthdate) && (
            <Typography className={classes.subTitle}>
              Please, fill in this missing information.
            </Typography>
          )}
          {needLastName && (
            <Box className={classes.lastNamefieldBox}>
              <Field
                name={`lastName`}
                component={TextInput}
                label="Last name"
                placeholder="Last name"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                required
              />
            </Box>
          )}
          {needBirthdate && (
            <Box className={classes.fieldBox}>
              <Field
                name={"birthDate"}
                required
                component={DateInput}
                maxDate={new Date()}
                label="Birthdate"
                ariaLabel="Birthday select"
                domesticId={domesticCountryId}
                countryId={user.countryId}
                helperText="So we can provide a custom experience for you."
              />
            </Box>
          )}
          {type !== programTypes.CLUB && type !== programTypes.COMMUNITY_PARTNERSHIP && (
            <>
              <Typography className={classes.subTitle}>
                We are changing the face of tech! Help us track our progress.
              </Typography>
              <Box className={classes.fieldBox}>
                <Field
                  name={`raceEthnicity`}
                  component={Selector}
                  label={enrollmentForm?.ethnicityField?.labelPrompt || ""}
                  options={etnicities}
                  onValueChange={(value) => {
                    setShowMultiEnticity(value === "Multiracial");
                    setShowAsianEtnicity(value === "Asian");
                  }}
                  errorIsHtml
                  required
                />
              </Box>
              {showAsianEtnicity && (
                <Box className={classes.raceFieldBox}>
                  <Field
                    name="raceEthnicityAsian"
                    component={CheckboxGroup}
                    title={"Please select one or more"}
                    className={classes.item}
                    defaultItems={raceEthnicityAsian}
                    items={asianItems}
                  />
                </Box>
              )}
              {showMultiEnticity && (
                <Box className={classes.fieldBox}>
                  <Field
                    name="raceEthnicityMultiracial"
                    component={CheckboxGroup}
                    title={"Please select one or more"}
                    className={classes.item}
                    defaultItems={raceEthnicityMultiracial}
                    items={multiRaceOptions}
                  />
                </Box>
              )}
              <Box className={classes.fieldBox}>
                <Field
                  name={`collegeMajorId`}
                  component={Selector}
                  label={enrollmentForm?.collegeMajorField?.labelPrompt || ''}
                  options={collegeMajors || []}
                  required
                />
              </Box>
              {showAdditionalMajor && (
                <>
                  <Box className={classes.fieldBox}>
                    <Field
                      name={`collegeAdditionalMajorId`}
                      component={Selector}
                      label={enrollmentForm?.collegeMajorField?.labelPrompt || ''}
                      options={collegeMajors || []}
                    />
                  </Box>
                  <TextCustomButton
                    className={classes.additionalButton}
                    mode="primary"
                    onClick={() => {
                      setShowAdditionalMajor(false);
                    }}
                    label={enrollmentForm.removeMajorLabel}
                  />
                </>
              )}
              {showMinor && (
                <>
                  <Box className={classes.fieldBox}>
                    <Field
                      name={`collegeMinorId`}
                      component={Selector}
                      label={enrollmentForm?.collegeMinorField?.labelPrompt || ''}
                      options={collegeMajors || []}
                    />
                  </Box>
                  <TextCustomButton
                    className={classes.additionalButton}
                    mode="primary"
                    onClick={() => {
                      setShowMinor(false);
                    }}
                    label={enrollmentForm.removeMajorLabel}
                  />
                </>
              )}
              {showAdditionalMinor && (
                <>
                  <Box className={classes.fieldBox}>
                    <Field
                      name={`collegeAdditionalMinorId`}
                      component={Selector}
                      label={enrollmentForm?.collegeAdditionalMinorField?.labelPrompt || ''}
                      options={collegeMajors || []}
                    />
                  </Box>
                  <TextCustomButton
                    className={classes.additionalButton}
                    mode="primary"
                    onClick={() => {
                      setShowAdditionalMinor(false);
                    }}
                    label={enrollmentForm.removeMajorLabel}
                  />
                </>
              )}
              <Box>
                <Box className={classes.previousEmail}>
                  {(!showMinor || !showAdditionalMinor) && (
                    <TextCustomButton
                      className={classes.additionalButton}
                      mode="primary"
                      onClick={() => {
                        if (!showMinor) {
                          setShowMinor(true);
                          return;
                        } else {
                          setShowAdditionalMinor(true);
                          return;
                        }
                      }}
                      label={enrollmentForm.addAnotherMinorLabel}
                    />
                  )}
                  {!showAdditionalMajor && (
                    <TextCustomButton
                      className={classes.additionalButton}
                      mode="primary"
                      onClick={() => {
                        setShowAdditionalMajor(!showAdditionalMajor);
                      }}
                      label={
                        showAdditionalMajor
                          ? enrollmentForm.removeMajorLabel
                          : enrollmentForm.addAnotherMajorLabel
                      }
                    />
                  )}
                </Box>
              </Box>
              <Box className={classes.fieldBox}>
                <Field
                  name={`collegeGraduationYear`}
                  component={Selector}
                  required
                  className={classes.item}
                  label={enrollmentForm?.collegeGraduationYearField?.labelPrompt || ''}
                  options={
                    collegeGraduationYearField
                      ? collegeGraduationYearField.options
                      : []
                  }
                />
              </Box>
              <Box className={classes.participatedInGirlsWhoCodeProgram}>
                <Field
                  name="participatedInGirlsWhoCodeProgram"
                  component={CheckboxGroup}
                  items={participationItems}
                  title={
                    "Have you participated in a Girls Who Code program before? Check all that apply"
                  }
                />
              </Box>
              <Box className={classes.fieldBox}>
                <Field
                  name="previousEmail"
                  component={EmailInput}
                  label="Email Used"
                  subTitle={(<Box>(if you used different email for your other program)</Box>)}
                  placeholder="Email"
                  isIconEnable={false}
                  shrink={true}
                />
              </Box>
              <Box className={clsx(classes.fieldBox, classes.showCommunicationsOpportunities)}>
                <Field
                  name="showCommunicationsOpportunities"
                  component={CheckBox}
                  label={
                    <div className={classes.communicationOpportunities}>Send me communications about Girls Who Code Opportunities!</div>
                  }
                />
              </Box>
              {showCommunicationOpportunities && (
                <>
                  <Box className={classes.fieldBox}>
                    <Field
                      name="socialProfileLinkedin"
                      component={TextInput}
                      label={enrollmentForm?.socialLinkedinField?.labelPrompt || ''}
                      placeholder="Linkedin Profile"
                      className={classes.item}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                  <Box className={classes.fieldBox}>
                    <Field
                      name="phoneNumber"
                      component={PhoneInput}
                      color="primary"
                      label={enrollmentForm?.phoneField?.labelPrompt || ''}
                      isDomestic={domesticCountryId === user.countryId}
                      errorIsHtml
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                </>
              )}
            </>
          )}
          {type === programTypes.CLUB && <Box className={classes.fieldBox}>
            <Field
              name={"graduationYear"}
              component={Selector}
              required
              label={enrollmentForm?.gradeField?.labelPrompt || ''}
              options={
                enrollmentForm.gradeField
                  ? enrollmentForm.gradeField.options
                  : []
                }
              errorIsHtml
            />
          </Box>}
        </Container>
        <FocusError/>
      </Form>
    </Formik>
  );
};

export default ThirdStep;
