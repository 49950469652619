import React from "react";
import clsx from 'clsx';

import { Field } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Selector from "../../../components/selector/Selector";
import TextInput from "../components/TextInput";
import TagChipSelector from "../components/TagChipSelector";

import * as colors from "../../.././theme/colors";

const useStyles = makeStyles((theme) => ({
  fieldWithMargin: {
    marginBottom: 20,
    '& .MuiOutlinedInput-input': {
      fontSize: 14,
    }
  },
  textareaShrink: {
    position: "relative",
    marginBottom: "-16px",
  },
  additionalMargin: {
    marginBottom: 20,
  },
  programSelect: {
    marginTop: 25,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    '& .MuiTypography-root': {
      fontSize: 14,
    },
  },
  subTitle: {
    color: colors.darkThemeBlueGray,
    fontSize: 14,
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      padding: "0 16px",
    },
  },
  secondStepWrapper: {
    padding: '0 24px',
    [theme.breakpoints.down("xs")]: {
      padding: '0 14px',
    },
  },
  fieldWrapper: {
    fontSize: 14,
    margin: "0 0 25px 0",
    '& .MuiInputLabel-outlined': {
      backgroundColor: colors.white,
    }
  },
  dropdownStyle: {
    '& .MuiMenuItem-root': {
      fontSize: 14,
    },
    '& .MuiTypography-body1': {
      fontSize: 14,
    },
  }
}));

const SecondStep = ({
  validateTeamMembers,
  userPrograms,
  validateProgram,
  programsList,
  getProgramMembers,
  isLoadingMembersList,
  defaultProgramId,
  labels,
  formValues,
}) => {
  const classes = useStyles();
  const [showProgramMembers, setShowProgramMembers] = React.useState(true);

  let teamMembersList = programsList
    .filter(item => !!item && !!item.members && !!item.members.length);

  return (
    <Box className={classes.secondStepWrapper}>
      <Box>
        <Typography className={userPrograms.length > 1 ? classes.subTitle : clsx(classes.subTitle, classes.additionalMargin)}>
          {"Step 2: Program & Team"}
        </Typography>
      </Box>
      {userPrograms.length > 1 && (
        <Box className={classes.fieldWrapper}>
          <Field
            component={Selector}
            label="Program"
            helperText="Program"
            name="selectedProgram"
            options={userPrograms}
            validate={validateProgram}
            className={classes.programSelect}
            onValueChange={getProgramMembers}
            defaultProgramId={defaultProgramId}
            setShowProgramMembers={setShowProgramMembers}
            dropDownclassName={classes.dropdownStyle}
            required
          />
        </Box>
      )}
      {showProgramMembers && !!teamMembersList.length && 
        formValues.selectedProgram != defaultProgramId && (
          <Field
            className={classes.fieldWithMargin}
            component={TagChipSelector}
            placeholder={labels.submissionFormGroupMembersPlaceholder || ""}
            name="teamMembers"
            label="Additional Team Members"
            validate={validateTeamMembers}
            isLoading={isLoadingMembersList}
            defaultProgramId={defaultProgramId}
            data={programsList}
            setShowProgramMembers={setShowProgramMembers}
            dropDownclassName={classes.dropdownStyle}
            enableSearch={true}
            groupedSelect={true}
            errorIsHtml={true}
          />
      )}
        <Field
          className={classes.fieldWithMargin}
          name="description"
          component={TextInput}
          placeholder={labels.submissionFormDescriptionPlaceholder || ""}
          label={labels.submissionFormDescriptionLabel || ""}
          rows="10"
          multiline
          maxLength={1200}
          maxLengthInfo={
            "Characters remaining: ${value} for Project description"
          }
          aria-live="polite"
          InputLabelProps={{ shrink: true, classes: { shrink: classes.textareaShrink } }}
        />
    </Box>
  );
};

export default SecondStep;
