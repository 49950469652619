import React, { useState, useEffect } from 'react';

import { Field } from 'formik';

import { makeStyles } from '@material-ui/core/styles';
import TextInput from '../../../components/TextInput';
import TextArea from '../../../components/TextArea';

import clsx from 'clsx';
import { Typography, Box, Link } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import HdrStrongRoundedIcon from '@material-ui/icons/HdrStrongRounded';
import EmailIcon from '@material-ui/icons/EmailOutlined';

import TextCustomButton from '../../../components/text-button/text-button';
import RadioSelector from '../../../components/radioGroup/radioGroup';
import Signature from '../../../components/signature/signature';
import { handleKeyPress } from '../../../utils/accessibility';

import * as colors from '../../../theme/colors';
import * as moment from 'moment';

import { createMarkup, isSchoolClub } from '../../../utils/generic';
import { HQModal } from '../../../components/modals/HQModal';
import CustomButton from '../../../components/customButton/customButton';
import { PageLeaveModal } from '../../../components/modals/pageLeaveWarning';

const useStyles = makeStyles((theme) => ({
  title: {
    color: colors.darkThemeBlueGray,
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  subTitle: {
    color: colors.darkThemeBlueGray,
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  boxWrapper: {
    marginTop: 20,
  },
  flippedIcon: {
    '& svg': {
      transform: 'rotate(180deg)',
    },
  },
  editBtn: {
    display: 'flex',
    marginLeft: 'auto',
  },
  modalBtnGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  requiredBox: {
    display: 'flex',
  },
  starLabel: {
    color: colors.redA700,
    marginLeft: 1,
  },
  item: {
    display: 'flex',
    marginBottom: 20,
    '& .MuiTypography-body1': {
      width: '40%',
      margin: 'auto 0',
      fontWeight: 'bold',
    },
    '& .MuiTypography-body2': {
      width: '60%',
      margin: 'auto 0',
      fontSize: '1rem',
      color: colors.dartThemeBlueGray,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',

      '& button': {
        textAlign: 'left',
      },
    },
  },
  infoContainer: {
    border: '1px solid #7A7A78',
    padding: '5px 15px',
  },
  infoLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  linkBtn: {
    textDecoration: 'none',
    textTransform: 'inherit',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  labelStyle: {
    fontSize: 14,
    margin: 0,
    padding: 0,
  },
  agreementBox: {
    overflowY: 'scroll',
    height: 250,
    fontSize: 14,
    border: '1px solid black',
    padding: '0px 10px',
  },
  signatureContainer: {
    position: 'relative',
    border: `1px solid ${colors.lightGrey}`,
    width: 400,
    margin: '25px auto',
    [theme.breakpoints.down('xs')]: {
      width: 280,
      maxWidth: '100%',
    },
  },
  initialBox: {
    margin: 'auto',
    display: 'flex',
    width: 400,
    [theme.breakpoints.down('xs')]: {
      width: 280,
    },
  },
  initialLabel: {
    width: 'fit-content',
    margin: 'auto 5px',
    color: colors.lightGrey,
  },
  initialText: {
    width: '60%',
  },
  radioBox: {
    marginLeft: 20,
  },
  messageArea: {
    maxWidth: 400,
    minWidth: 400,
    minHeight: 60,
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 280,
      minWidth: 280,
    },
  },
  messageWrapper: {
    width: 'fit-content',
    margin: 'auto',
  },
  notificationMsgLabel: {
    color: colors.redA400,
    fontSize: 14,
  },
  decisionMakerMsg: {
    fontSize: 14,
    lineHeight: 1.5,
    color: colors.darkBlue100,
  },
  infoLink: {
    color: colors.darkThemeGreen,
    fontSize: 14,
    fontWeight: 700,
    textDecoration: 'none',
    padding: '0 0 0 5px',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  anonymousMsg: {
    color: colors.darkThemeBlueGray,
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 10,
    lineHeight: 1.5,
    '& a': {
      textDecoration: 'none',
      textTransform: 'inherit',
      padding: '0px 5px',
      color: colors.darkThemeGreen,
      fontWeight: 'bold',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
  thirdStepWrapper: {
    padding: "0 24px",
  }
}));

const getDecisionMaker = ({ email, firstName, lastName }) => {
  let result = { email: '', fullName: '' };

  if (!firstName || !lastName) {
    return result;
  }

  result.fullName = `${firstName} ${lastName}`;

  if (email) {
    result.email = email;
  }

  return result;
};

const renderAgreement = (content, currDate, user, program) => {
  return content
    .replace('{{currentDate}}', currDate)
    .replace('{{fullName}}', !!user ? `${user.firstName} ${user.lastName}` : '')
    .replace('{{hostOrganizationType}}', program.clubType)
    .replace(/{{hostOrganizationName}}/g, program.hostOrganizationName)
    .replace(/{{signature}}/g, 'Please sign below.');
}

const ThirdStep = ({
  labels,
  user,
  program,
  isDecisionMaker,
  setShowSignature,
  showSignature,
  setSignAgreementMode,
  signAgreementMode,
  values,
  isEditMode,
  setIsEditMode,
  setValues,
  errors,
  setTouched,
}) => {
  const classes = useStyles();

  const [showNotificationMsg, setShowNotificationMsg] = useState(false);
  const [currentValues, setCurrentValues] = useState({});
  const [isEscapeConfirmDialog, setEscapeConfirmDialog] = useState(false);

  useEffect(() => {
    setCurrentValues(values);
    setTouched({ dmFirstName: false, dmLastName: false, dmEmail: false });
  }, []);

  const decisionMakerInfo = getDecisionMaker({ firstName: values.dmFirstName, lastName: values.dmLastName, email: values.dmEmail });
  const decisionMakerInfoMsg = labels.thirdStepDecisionMakerInfoMsg
    .replace('{{decisionMakerEmail}}', decisionMakerInfo.email)
    .replace('{{decisionMakerName}}', decisionMakerInfo.fullName);

  const items = [
    { id: 1, name: true, alias: 'Yes. I am authorized to sign.' },
    {
      id: 2,
      name: false,
      alias: 'No. My Signatory will sign the Agreement.',
    },
  ];

  const decisionMakerItems = [
    { id: 1, name: true, alias: 'I have read and consent to the agreement.' },
    { id: 2, name: false, alias: 'I do NOT consent to the agreement.' },
  ];
  const currDate = moment.parseZone(new Date()).format('MM/DD/YYYY');

  const content = isSchoolClub(program.clubType)
      ? labels.thirdStepAgreementFileSchoolClubs.content
      : labels.thirdStepAgreementFile.content;
    
  const template = renderAgreement(content, currDate, user, program);

  var anonymousMsg = labels.thirdStepAnonymousSignedAgreementMsg
    .replace('{{programCode}}', program.code)
    .replace('{{programName}}', program.name)
    .replace('{{programCode}}', program.code);

  const handleSave = () => {
    setCurrentValues({ ...values });
    setIsEditMode(!isEditMode);
  }

  const onOpenDMEditModal = () => {
    setIsEditMode(true);
  };

  const onCloseDMEditModal = () => {
    if (values.dmLastName !== currentValues.dmLastName || 
        values.dmFirstName !== currentValues.dmFirstName ||
        values.dmEmail !== currentValues.dmEmail) {
          setEscapeConfirmDialog(true);
          return;
    }
    setTouched({ dmFirstName: false, dmLastName: false, dmEmail: false });
    setIsEditMode(false);
  };

  // if some information is missing - show modal with form
  useEffect(() => {
    if (!values.dmLastName || !values.dmFirstName || !values.dmEmail) {
      setIsEditMode(true);
    }
  }, []);
  

  return (
    <Box className={classes.thirdStepWrapper}>
      <Box>
        <Typography className={classes.title}>
          {`Step 3: ${labels.thirdStepLabel}`}
        </Typography>
      </Box>

      {!isDecisionMaker && !program.anonymousUser  && (
        <>
          <Box className={classes.boxWrapper}>
            <Typography className={classes.subTitle}>
              Please review the following information:
            </Typography>
          </Box>
          <Box className={classes.infoContainer}>
            <ListItemText
              primary="Signatory:"
              className={classes.item}
              secondary={
                values.dmFirstName || values.dmLastName ? `${values.dmFirstName || ''} ${values.dmLastName || ''}` : ''
              }
            />
            <ListItemText
              primary="Email:"
              className={classes.item}
              secondary={values.dmEmail || ''}
            />
            <TextCustomButton
              className={classes.editBtn}
              mode="primary"
              onClick={onOpenDMEditModal}
              onKeyDown={handleKeyPress(onOpenDMEditModal)}
              label={'Edit'}
            />
          </Box>
        </>
      )}

      {isEditMode && (
        <>
          <HQModal
            isOpen={isEditMode}
            onClose={onCloseDMEditModal}
            titleOptions={{ isUpperCase: true, isBold: true }}
            title="Review your signatory info"
          >
            <Box>
              <Typography id="dialog-description" className={classes.subTitle}>
                The
                <Link
                  target="_blank"
                  className={classes.infoLink}
                  href={labels.thirdStepDecisionMakerLink}
                >
                  Signatory
                </Link>{' '}
                must be an employee of the Club Host Site.
              </Typography>
            </Box>
            <Box className={clsx(classes.boxWrapper, classes.flippedIcon)}>
              <Field
                name={`dmFirstName`}
                component={TextInput}
                label="First name"
                Icon={HdrStrongRoundedIcon}
                required
              />
            </Box>
            <Box className={classes.boxWrapper}>
              <Field
                name={`dmLastName`}
                component={TextInput}
                label="Last name"
                Icon={HdrStrongRoundedIcon}
                required
              />
            </Box>
            <Box className={classes.boxWrapper}>
              <Field
                name={`dmEmail`}
                component={TextInput}
                label="Email"
                Icon={EmailIcon}
                required
              />
            </Box>
            <Box className={clsx(classes.boxWrapper,classes.modalBtnGroup)}>
            <TextCustomButton
              mode="primary"
              onClick={onCloseDMEditModal}
              onKeyDown={handleKeyPress(onCloseDMEditModal)}
              label={'Cancel'}
            />
            <CustomButton
              onClick={handleSave}
              onKeyDown={handleKeyPress(handleSave)}
              disabled={errors.dmLastName || errors.dmFirstName || errors.dmEmail}
              label={'Save'}
            />
            </Box>
          </HQModal>
          <PageLeaveModal
            onConfirm={() => {
              setEscapeConfirmDialog(false);
              setValues({ ...currentValues });
              setTouched({ dmFirstName: false, dmLastName: false, dmEmail: false });
              setIsEditMode(false);
            }}
            onCancel={() => { setEscapeConfirmDialog(false); }}
            open={isEscapeConfirmDialog}
          />
        </>

      )}

      {program.anonymousUser && (
        <Box>
          <div
            className={classes.anonymousMsg}
            dangerouslySetInnerHTML={createMarkup(anonymousMsg)}
        />
        </Box>
      )}

      {!program.anonymousUser && (
        <>
          {isDecisionMaker || signAgreementMode ? (
            <>
              <Box className={classes.boxWrapper}>
                <Typography className={classes.subTitle}>
                  {labels.thirdStepInfoLabel}
                </Typography>
              </Box>
              <Box className={classes.agreementBox}>
                <div dangerouslySetInnerHTML={createMarkup(template)} />
              </Box>
              <Box className={classes.boxWrapper}>
                <Typography className={classes.subTitle}>
                  Please indicate that you have read above agreement.*
                </Typography>
              </Box>
              <Box className={classes.radioBox}>
                <Field
                  name="clubParticipationAgreement"
                  component={RadioSelector}
                  labelStyle={classes.labelStyle}
                  items={decisionMakerItems}
                  onValueChange={(value) => {
                    setShowSignature(value);
                  }}
                />
              </Box>

              {showSignature && (
                <>
                  <Box className={classes.signatureContainer}>
                    <Field name="signature" component={Signature} />
                  </Box>
                  <Box className={classes.initialBox}>
                    <Typography className={classes.initialLabel}>
                      Signature of
                    </Typography>
                    <Field
                      name="initials"
                      component={TextInput}
                      className={classes.initialText}
                    />
                  </Box>
                </>
              )}

              {!showSignature && (
                <>
                  <div className={classes.notificationMsgLabel}>
                    <p>Please note this means your Club will <strong>Not</strong> be renewed.</p>
                  </div>
                  <Box className={classes.boxWrapper}>
                    <Typography className={classes.subTitle}>
                      We would love to understand why. Leave us some feedback if
                      possible.
                    </Typography>
                  </Box>
                  <Box>
                    <Box className={classes.messageWrapper}>
                      <Field
                        name={`notSignedAgreementMsg`}
                        component={TextArea}
                        className={classes.messageArea}
                        maxLengthStartingFrom={200}
                        maxLengthInfo={'Characters remaining: ${value}'}
                        aria-live="polite"
                        rowsMin={'3'}
                        errorIsHtml={true}
                      />
                    </Box>
                  </Box>
                </>
              )}
            </>
          ) : (
            <>
              <Box className={classes.boxWrapper}>
                <Typography>
                  Are you authorized by your Signatory to sign the
                  Participation Agreement?
                </Typography>
              </Box>
              <Field
                name="autorizedToSign"
                component={RadioSelector}
                labelStyle={classes.labelStyle}
                items={items}
                onValueChange={(value) => {
                  setSignAgreementMode(value);
                  setShowSignature(value);
                  setShowNotificationMsg(!value);
                }}
              />
              {showNotificationMsg && (
                <Box className={classes.boxWrapper}>
                  <Typography className={classes.subTitle}>
                    Remind your Signatory to sign the Participation
                    Agreement:
                  </Typography>
                  <div
                    className={classes.decisionMakerMsg}
                    dangerouslySetInnerHTML={createMarkup(decisionMakerInfoMsg)}
                  ></div>
                </Box>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default ThirdStep;
