import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as moment from 'moment';

import Box from '@material-ui/core/Box';

import validationSchema from './validationSchemas';
import FocusError from './FocusError';
import SignUpForm from './SignUpForm';
import { connectTo } from '../../../utils/generic';
import * as actions from '../../../actions/signUp';
import * as trackingActions from '../../../actions/tracking';
import * as globalActions from "../../../actions/global";
import { checkIsClubsDomain, validateBirthDate } from '../../../utils/generic';
import { DOMESTIC_ID } from '../../../utils/countriesDict';
import Roles from "../../../utils/permissions";

import {
  SIGNUP_TAB,
  SIGNUPV2_PAGE1_DONE,
  SIGNUP_BACK_NAVIGATION,
  SIGNUPV2_ACCOUNT_INFO_FILLED,
} from "../../../constants/trackingEvents";

const SignUp = (props) => {
  const initialValues = {
    country: '',
    birthDate: '',
    role: props.defaultRole || '',
    email: props.invitedEmail || '',
    password: '',
    firstName: '',
    lastName: '',
    agreeCommunicate: false,
    termsAndConditions: false,
    clubCode: props?.invitationProgram?.code || '',
    isParent: false,
    invitationProgram: props.invitationProgram,
  };
  const {qrClubCode} = props;
  const invitedEmail = props.invitedEmail;
  const sendTracking = props.createUsageTrackingEvent;
  const checkingByCodeResult = props.checkingByCodeResult ? { ...props.checkingByCodeResult } : props.checkingByCodeResult;
  const checkClubByCode = props.checkClubByCode;
  const cleanCheckClubByCode = props.cleanCheckClubByCode;
  const [values, setValues] = useState(initialValues);
  const [page, setPage] = useState(0);
  const [startTime, setStartTime] = useState(new Date());
  const [submitting, setSubmitting] = useState(false);
  const [isClubCodeValidating, setClubCodeValidating] = useState(false);
  const [clubCode, setClubCode] = useState(false);
  const [isUserCountryDomestic, setUserCountryDomestic] = useState();
  const [isErrorByClubCode, setErrorByClubCode] = useState(false);
  const registerAction = props.register;
  const { signUpContent } = props;
  useEffect(() => {
    setClubCodeValidating(props.isClubCodeValidating);
  }, [props.isClubCodeValidating]);

  useEffect(() => {
    sendTracking
    ({
      action: SIGNUP_TAB,
      eventTime: 0,
      data: {tab:"Sign up"},
    });
    return () => {

    };
  }, []);

  useEffect(() => {
    if (props?.invitationProgram?.code) {
      setValues({...values, clubCode: props?.invitationProgram?.code})
    } 
  },[props?.invitationProgram?.code])

  const isStepDataChanged = (newValues, oldValues, fields) => {
    for (const field of fields) {
      if (oldValues[field] !== newValues[field]) {
        return true;
      }
    }
    return false;
  }

  const reinitStepData = (newValues, fields) => {
    const result = initialValues;
    for (const field of fields) {
      result[field] = newValues[field];
    }
    return result;
  }

  const isClubDomain = checkIsClubsDomain();
  return (
    <>
      <Formik
        initialValues={values}
        enableReinitialize={true}
        validateOnMount={true}
        validate={(values) => {
          let errors = {};
          setClubCode(values.clubCode);
          setUserCountryDomestic(values.country === DOMESTIC_ID);

          const isDobInvalid = validateBirthDate(values.birthDate);
          if (isDobInvalid) {
            errors.birthDate = isDobInvalid;
          }

          return errors;
        }}
        validationSchema={validationSchema(page, values.role, signUpContent)}
        onSubmit={(submittedValues, actions) => {

          //Do not send second request
          if (submitting) {
            return true;
          }

          if (submittedValues.country) {
            const selectedCountryId = parseInt(submittedValues.country, 10);
            localStorage.setItem('signupCountryId', selectedCountryId);
          }

          let dobDate = moment.parseZone(submittedValues.birthDate).format();
          const age = moment().diff(dobDate, 'year');

          if (page === 0) {
            actions.setSubmitting(false);
            actions.setTouched(false);

            const step0Fields = ['country', 'birthDate', 'termsAndConditions', 'isParent'];
            if (isStepDataChanged(submittedValues, values, step0Fields)) {
              submittedValues = reinitStepData(submittedValues, step0Fields);
            }

            const updatedValues = {
              ...submittedValues,
              role: isNaN(submittedValues.role) ? parseInt(submittedValues.role && submittedValues.role.split('_')[1]) : submittedValues.role,
            };

            sendTracking
            ({
              action: SIGNUPV2_PAGE1_DONE,
              eventTime: (+(new Date()) - +startTime) / 1000,
              data: {...updatedValues},
            });

            setStartTime(new Date());
            setValues(updatedValues);

            if (submittedValues.isParent && age >= 18) {
              setPage(1);
            } else {
              // if age < 13 - remove "username", because this field exist only for this users
              if (age < 13 && isClubDomain) {
                setValues({ ...updatedValues, username: null });
                submittedValues.username = null;
                setPage(2);
              }
              if (age >= 13 || age < 13 && !isUserCountryDomestic && !isClubDomain) {
                setPage(page + 1);
              }
            }
          } else if (page === 1 && values.role === Roles.Student) {
            sendTracking
            ({
              action: SIGNUPV2_ACCOUNT_INFO_FILLED,
              eventTime: (+(new Date()) - +startTime) / 1000,
              data: {...submittedValues},
            });
            setSubmitting(true);
            props.register({
              invitationCode: initialValues.invitationProgram?.code,
              ...submittedValues,
              email: submittedValues.email.trim(),
              role: isNaN(submittedValues.role) ? parseInt(submittedValues.role && submittedValues.role.split('_')[1]) : submittedValues.role,
            });
          } else if (page === 1) {
            const values = { ...submittedValues };
            if (age < 13 && values.username &&
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}))$/i.test(values.username.trim())) {

              values.parentEmail = values.email;
              values.email = values.username;
              values.username = null;
            }

            setStartTime(new Date());
            setSubmitting(true);
            props.register({
              qrClubCode,
              ...values,
              email: values.email.trim(),
            });
          } 
          else if (page === 2) {
            actions.setTouched(false);
            if (submittedValues.clubCode) {
              checkClubByCode({ programCode: submittedValues.clubCode });
            }
          } else {
            sendTracking
            ({
              action: SIGNUPV2_ACCOUNT_INFO_FILLED,
              eventTime: (+(new Date()) - +startTime) / 1000,
              data: {...submittedValues},
            });
            setStartTime(new Date());
            setSubmitting(true);
            props.register({
              ...submittedValues,
              email: submittedValues.email.trim(),
              role: parseInt(submittedValues.role.split('_')[1]),
              qrClubCode,
            });
          }
        }}
      >
        {(props) => <Box>
          <SignUpForm
            page={page}
            onBack={() => {
              const age = moment().diff(props?.values?.birthDate, 'year');
              sendTracking
              ({
                action: SIGNUP_BACK_NAVIGATION,
                eventTime: (+(new Date()) - +startTime) / 1000,
              });
              setStartTime(new Date());
              if (page === 2) {
                cleanCheckClubByCode();
                setErrorByClubCode(false);
                setPage(0);
                return;
              }
              if (page === 1 && age < 13 && isClubDomain) {
                cleanCheckClubByCode();
                setPage(2);
                return;
              }
              if (page === 1 && age < 13 && !isUserCountryDomestic) {
                setPage(0);
                return;
              }
              setPage(page - 1);
            }}
            errors={props.errors}
            form={props.form}
            signUpContent={signUpContent}
            isSubmitting={submitting}
            invitedEmail={invitedEmail}
            setValues={setValues}
            register={registerAction}
            setPage={setPage}
            checkingByCodeResult={checkingByCodeResult}
            clubCode={clubCode}
            isClubCodeValidating={isClubCodeValidating}
            setSubmittingForm={props.setSubmitting}
            cleanCheckClubByCode={cleanCheckClubByCode}
            setErrorByClubCode={setErrorByClubCode}
            isErrorByClubCode={isErrorByClubCode}
            isParent={values.isParent}
            qrClubCode={qrClubCode}
          />
          <FocusError />
        </Box>
        }
      </Formik>
    </>
  );
};

export default connectTo(
  (state) => {
    return {
      auth: state.auth,
      checkingByCodeResult: state.signUp.checkingByCodeResult,
      isClubCodeValidating: state.signUp.isClubCodeValidating,
      checkClubByCode: state.signUp.checkClubByCode,
    }
  },
  {
    ...actions,
    ...globalActions,
    ...trackingActions,
  },
  SignUp,
);
