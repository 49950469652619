import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";

import { PasswordInput } from "../../../components/PasswordInput";
import { validatePasswordRules, filterFieldsByKey, filterFieldsWithKey } from '../../../utils/generic';
import * as colors from "../../../theme/colors";
import CustomButton from "../../../components/customButton/customButton";
import { HQModal } from "../HQModal";
import { PageLeaveModal } from '../../../components/modals/pageLeaveWarning';

const useStyles = makeStyles(theme => ({
  addButtonExplanation: {
    color: theme.palette.primary.main,
    fontSize: 12
  },
  marginTop30: {
    marginTop: 30
  },
  parentFormTitle: {
    fontSize: 12,
    color: theme.palette.primary.main,
    marginBottom: 10,
    marginTop: 30
  },
  subTitle: {
    color: theme.palette.primary.main,
    fontSize: 16,
    lineHeight: 1.75,
    textAlign: "center"
  },
  buttonGroup: {
    marginTop: "30px",
    height: 42,
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    boxShadow: "none"
  },
  button: {
    borderRadius: 5,
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: colors.grey400,
    color: "white",
    "&:hover": {
      backgroundColor: colors.grey500
    }
  },
  submitButton: {
    borderRadius: 5,
    backgroundColor: colors.tealA700,
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: colors.teal500
    }
  },
  error: {
    color: '#ff0000',
    fontSize: 14,
    paddingTop: 15
  }
}));

export const ChangePasswordModal = ({ isOpen, onClose, onSave, changePasswordContent, passwordChanged, passwordNotSet, user, showPasswordChangeResult }) => {
  const classes = useStyles();

  const [isEscapeConfirmDialog, setEscapeConfirmDialog] = useState(false);
  const [isDataChanged, setDataChanged] = useState(false);

  const resetFields = filterFieldsWithKey(changePasswordContent.resetStepFields, "id");
  const newPasswordContent = resetFields.newPassword;
  const currentErrors = filterFieldsByKey(resetFields.currentPassword.validationErrors, "messageCode", "message");
  const newErrors = filterFieldsByKey(newPasswordContent.validationErrors, "messageCode", "message");
  const confirmErrors = filterFieldsByKey(resetFields.retypePassword.validationErrors, "messageCode", "message");

  const passwordIsSetValidationSchema = Yup.object({
    currentPassword: !passwordNotSet ? Yup.string().required(currentErrors.requiredField) : Yup.string(),
    newPassword: Yup.string()
      .required(newErrors.requiredField),
    confirmPassword: Yup.string()
      .required(confirmErrors.requiredField)
      .test("match", confirmErrors.differentPasswords, function(
        confirmPassword
      ) {
        return confirmPassword === this.parent.newPassword;
      })
  })

  const passwordNotSetValidationSchema = Yup.object({
    newPassword: Yup.string()
      .required(newErrors.requiredField),
    confirmPassword: Yup.string()
      .required(confirmErrors.requiredField)
      .test("match", confirmErrors.differentPasswords, function(
        confirmPassword
      ) {
        return confirmPassword === this.parent.newPassword;
      })
  })

  const validateNewPassword = value => {
    const validateResult = validatePasswordRules(value, user.email, user.username, user.firstName);
    if (Object.keys(validateResult).length > 0) {
      return newErrors.weakPassword;
    }
  }

  const onCloseChangePasswordModal = () => {
    if (isDataChanged) {
        setEscapeConfirmDialog(true);
    } else {
        setDataChanged(false);
        setEscapeConfirmDialog(false);
        onClose();
    }
  };

  return (
    <HQModal title='Change your password' isOpen={isOpen} onClose={onCloseChangePasswordModal}>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h4" className={classes.subTitle}>
          Please enter your new password
        </Typography>
        <Formik
          initialValues={{
            currentPassword: "",
            newPassword: "",
            confirmPassword: ""
          }}
          validate={() => {
            setDataChanged(true);
            return ;
          }}
          validationSchema={passwordNotSet ? passwordNotSetValidationSchema : passwordIsSetValidationSchema}
          onSubmit={values => {
            onSave(values);
          }}
        >
          <Form>
            {!passwordNotSet && <Field
              name={`currentPassword`}
              component={PasswordInput}
              label="Current password"
              className={classes.marginTop30}
              required={true}
            />}
            <Field
              name={`newPassword`}
              component={PasswordInput}
              label="New password"
              className={classes.marginTop30}
              validate={validateNewPassword}
              content={newPasswordContent}
              fromModal={true}
              firstName={user.firstName}
              email={user.email}
              username={user.username}
              required={true}
            />
            <Field
              name={`confirmPassword`}
              component={PasswordInput}
              label="Retype password"
              className={classes.marginTop30}
              required={true}
            />

            {showPasswordChangeResult && passwordChanged && !passwordChanged.saved && passwordChanged.error &&
              <div>
                <Typography variant="h4" className={classes.error}>
                  {passwordChanged.error}
                </Typography>
              </div>
            }

            <div className={classes.buttonGroup}>
              <CustomButton
                mode="textButton"
                onClick={() => isDataChanged ? setEscapeConfirmDialog(true) : onClose() }
                label={"Cancel"}
              />
              <CustomButton
                mode="primary"
                type="submit"
                label={"Save"}
              />
            </div>
          </Form>
        </Formik>
      </DialogContent>
      <PageLeaveModal
        onConfirm={() => { setDataChanged(false); setEscapeConfirmDialog(false); onClose(); }}
        onCancel={() => { setEscapeConfirmDialog(false); }}
        open={isEscapeConfirmDialog}
      />
    </HQModal>
  );
};
