import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import * as moment from "moment";
import * as momentTimezone from "moment-timezone";

import { HQModal } from "../HQModal";
import EmailInput from "../../emailInput/EmailInput";
import * as colors from "../../../theme/colors";
import DateInput from "../../../components/date-input/DateInput";
import TextInput from "../../../components/TextInput";
import Selector from "../../../components/selector/Selector";
import PhoneInput from "../../../components/PhoneInput";

import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";

import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import { makeStyles } from "@material-ui/core/styles";

import CustomButton from "../../../components/customButton/customButton";
import { validateUserNameRules, FocusError } from "../../../utils/generic";
import { PageLeaveModal } from '../../../components/modals/pageLeaveWarning';
import { usernameIsInUseMsg, formInavlidMsg, validNameRegex } from '../../../utils/userUtils';
import { DATE_FORMAT } from '../../../utils/constants';
import { verifyForm } from '../../../api/users';

const useStyles = makeStyles((theme) => ({
  addButtonExplanation: {
    color: theme.palette.primary.main,
    fontSize: 12,
  },
  marginTop30: {
    marginTop: "30px",
  },
  parentFormTitle: {
    fontSize: 12,
    color: theme.palette.primary.main,
    marginBottom: 10,
    marginTop: 30,
  },
  subTitle: {
    color: theme.palette.primary.main,
    fontSize: 16,
    lineHeight: 1.75,
    textAlign: "center",
  },
  buttonGroup: {
    marginTop: "30px",
    height: 42,
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    boxShadow: "none",
  },
  button: {
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: colors.grey400,
    color: "white",
    "&:hover": {
      backgroundColor: colors.grey500,
    },
  },
  submitButton: {
    marginLeft: "20px",
    backgroundColor: colors.tealA700,
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: colors.teal500,
    },
  },
}));

const getDate = (value) => {
  if (!value || isNaN(new Date(value).getDate())) {
    return null;
  }

  return moment.parseZone(value).format("MM/DD/YYYY");
};

export const UserProfileModal = ({
  user,
  timezones,
  userEditModal,
  domesticCountry,
  onClose,
  onSave,
  isClubChild
}) => {
  const isDomestic = user.countryId == "" || user.countryId === domesticCountry;
  const [isEscapeConfirmDialog, setEscapeConfirmDialog] = useState(false);
  const [isDataChanged, setDataChanged] = useState(false);

  const { firstName, lastName, birthDate, email, username } = user;
  const validateEmail = (value) => {
    if (!value) {
      return;
    }

    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}))$/i.test(
        value.trim()
      )
    ) {
      return formInavlidMsg;
    }

    return verifyForm({ firstName, lastName, birthDate, username, email: value })
    .then((result) => {
      return result.data
        ? null
        : formInavlidMsg;
    });
  };

  const validateUserName = (value) => {
    if (!value) {
      return;
    }

    const result = validateUserNameRules(value, user);
    if (result) {
      return result;
    }

    return verifyForm({ firstName, lastName, birthDate, email, username: value })
    .then((result) => {
      return result.data
        ? null
        : usernameIsInUseMsg;
    });
  };

  const onCloseAccountInfoModal = () => {
    if (isDataChanged) {
        setEscapeConfirmDialog(true);
    } else {
        setDataChanged(false);
        setEscapeConfirmDialog(false);
        onClose();
    }
  };

  const classes = useStyles();
  return (
    <HQModal title='Account info' isOpen={userEditModal} onClose={onCloseAccountInfoModal}>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h4" className={classes.subTitle}>
          Please choose the required field to update your info
        </Typography>
        <Formik
          initialValues={{
            firstName: user.firstName,
            lastName: user.lastName,
            birthDate: user.birthDate ? getDate(user.birthDate) : null,
            email: user.email || "",
            username: user.username || "",
            currentSchool: user.currentSchool,
            graduationYear: user.graduationYear,
            timezone: user.timezone || momentTimezone.tz.guess(),
            phoneNumber: user.phoneNumber || null,
          }}
          validate={() => {
            setDataChanged(true);
            return ;
          }}
          validationSchema={Yup.object({
            firstName: Yup.string()
              .matches(validNameRegex, 'You can not use numbers or symbols')
              .required("Required"),
            lastName: Yup.string()
              .matches(validNameRegex, 'You can not use numbers or symbols')
              .max(20, "Must be 20 characters or less")
              .required("Required"),
            birthDate: Yup.date()
              .max(new Date(), "Date of Birth cannot be the future date")
              .min((moment().subtract(100, 'years')), "Please check the format of your date of birth.")
              .required("Required")
              .typeError("Required"),
            email: Yup.string().when("username", {
              is: function (val) {
                return !val;
              },
              then: Yup.string("must be string").required("Required email or username"),
              otherwise: Yup.string(),
            }),
            username: Yup.string()
              .test("is-username-match-firstname", "Username must not contain your name", function () {
                const { firstName, username } = this.parent;
                const isMatchName = !!username && username.length > 0
                  && firstName && firstName.length > 0
                  && username.toLowerCase().includes(firstName.toLowerCase());
                return !isMatchName;
              }),
            timezone: Yup.string()
              .trim()
              .required("Required")
              .typeError("Required"),
          })}
          onSubmit={(values) => {
            let date;
            let username;
            if (values.birthDate) {
              date = moment.parseZone(values.birthDate).format(DATE_FORMAT);
            }

            // TO DO: Hotfix for unique usernames.
            // Avoid username to be empty string;
            username = !!values.username ? values.username : null;

            const newValues = { ...values, birthDate: date, username };
            onSave(newValues);
            onClose();
          }}
        >
          <Form noValidate>
            <Field
              name={`firstName`}
              component={TextInput}
              label="First name"
              className={classes.marginTop30}
              required={true}
              errorIsHtml={true}
            />
            <Field
              name={`lastName`}
              component={TextInput}
              label="Last name"
              className={classes.marginTop30}
              required={true}
              errorIsHtml={true}
            />
            <Field
              component={EmailInput}
              name="email"
              label="Email"
              className={classes.marginTop30}
              helperText="You'll use this to log in. (Teachers and Facilitators: Please use the same email from your Application.)"
              validate={validateEmail}
              showTooltip
              accessibleDescriptionText="Required email or username"
            />
            <Field
              name={`username`}
              component={TextInput}
              label="Username"
              className={classes.marginTop30}
              validate={validateUserName}
              accessibleDescriptionText="Required username or email"
              errorIsHtml={true}
            />
            <Field
              name="birthDate"
              component={DateInput}
              label="Birthdate"
              ariaLabel="Birthday select"
              maxDate={new Date()}
              className={classes.marginTop30}
              domesticId={domesticCountry}
              countryId={user.countryId}
              helperText="So we can provide a custom experience for you."
              required={true}
            />
            <Field
              name={`timezone`}
              component={Selector}
              label="Your Timezone"
              className={classes.marginTop30}
              options={timezones}
            />
            {!isClubChild && <Field
              name="phoneNumber"
              color="primary"
              label="Phone number"
              component={PhoneInput}
              Icon={LocalPhoneIcon}
              className={classes.marginTop30}
              isDomestic={isDomestic}
            />}
            <div variant="contained" className={classes.buttonGroup}>
              <CustomButton
                mode="textButton"
                onClick={() => isDataChanged ? setEscapeConfirmDialog(true) : onClose() }
                label={"Cancel"}
              />
              <CustomButton
                mode="primary"
                type="submit"
                label={"Save"}
              />
            </div>
            <FocusError />
          </Form>
        </Formik>
      </DialogContent>
      <PageLeaveModal
        onConfirm={() => { setDataChanged(false); setEscapeConfirmDialog(false); onClose(); }}
        onCancel={() => { setEscapeConfirmDialog(false); }}
        open={isEscapeConfirmDialog}
      />
    </HQModal>
  );
};
