import React from "react";

import { getIsFormInvalid } from '../../../api/users';

import { Formik, Field, Form } from "formik";
import * as Yup from "yup";

import * as moment from "moment";
import { HQModal } from "../../../components/modals/HQModal";
import EmailInput from "../../../components/emailInput/EmailInput";
import * as colors from "../../../theme/colors";
import DateInput from "../../../components/date-input/DateInput";
import TextInput from "../../../components/TextInput";
import Selector from "../../../components/selector/Selector";
import { usernameIsInUseMsg, formInavlidMsg } from '../../../utils/userUtils';
import { PageLeaveModal } from '../../../components/modals/pageLeaveWarning';

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { connectTo, createMarkup, isValidEmail } from "../../../utils/generic";
import CustomButton from "../../../components/customButton/customButton";

const useStyles = makeStyles(theme => ({
  addButtonExplanation: {
    color: theme.palette.primary.main,
    fontSize: 12
  },
  marginTop30: {
    marginTop: "30px"
  },
  parentFormTitle: {
    fontSize: 12,
    color: theme.palette.primary.main,
    marginBottom: 10,
    marginTop: 30
  },
  subTitle: {
    color: theme.palette.primary.main,
    fontSize: 16,
    lineHeight: 1.75,
    textAlign: "center"
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 22,
    fontWeight: "bold",
    margin: "25px auto 15px",
    maxWidth: 330,
    textAlign: "center"
  },
  buttonGroup: {
    marginTop: "30px",
    height: 42,
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    fontSize: 16,
    boxShadow: "none"
  },
  button: {
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: colors.grey400,
    color: "white",
    "&:hover": {
      backgroundColor: colors.grey500
    }
  },
  submitButton: {
    marginLeft: "20px",
    backgroundColor: colors.tealA700,
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: colors.teal500
    }
  },
  parentConsent: {
    fontSize: 16,
    marginTop: 30
  }
}));

// TO DO
const guardians = [
  { value: 1, label: "Mother" },
  { value: 2, label: "Father" },
  { value: 3, label: "Grandmother" },
  { value: 4, label: "Grandfather" },
  { value: 5, label: "Legal Guardian" },
  { value: 6, label: "Other" }
];

const getDate = (value) => {
  return moment.parseZone(value).format('MM/DD/YYYY');
};

const alphanumericRegex = /^[a-zA-Z0-9]*$/;

const ChildModal = ({ child, isOpen, onClose, onSave, contentful, domesticCountry, countryId }) => {
  const classes = useStyles();
  const [childLabels, setChildLabels] = React.useState(null);
  const [isDataChanged, setDataChanged] = React.useState(false);
  const [isEscapeConfirmDialog, setEscapeConfirmDialog] = React.useState(false);

  React.useEffect(() => {
    if (!contentful.GlobalComponentQuery) {
      return;
    }
    const {
      GlobalComponentQuery: { childrenManagementLabels }
    } = contentful;
    setChildLabels(childrenManagementLabels);
  }, [contentful]);

  const validateEmail = (value, values) => {
    if (!value) {
      return;
    }

    if (!value.trim().length || isValidEmail(value)) {
      return 'Incorrect email address';
    }

    return getIsFormInvalid({ ...values, email: value })
      .then((result) => {
        return result.data
          ? formInavlidMsg
          : null;
      });
  };

  const validateUserName = (value, values) => {
    if (!value) {
      return ;
    }

    if (!alphanumericRegex.test(value)){
      return `Username shouldn't contain speacial characters`;
    }

    return getIsFormInvalid({ ...values, username: value })
      .then((result) => {
        return result.data
          ? usernameIsInUseMsg
          : null;
      });
  };

  return (
    <HQModal title="Child Info" isOpen={isOpen} onClose={() => isDataChanged ? setEscapeConfirmDialog(true) : onClose()}>
      {childLabels && (
        <>
          <Formik
            enableReinitialize={true}
            isValidating={true}
            initialValues={{
              firstName: child.firstName,
              lastName: child.lastName,
              birthDate: child.birthDate ? getDate(child.birthDate) : "",
              email: child.email,
              kinshipId: child.kinshipId
            }}
            validate={() => {
              setDataChanged(true);
              return;
            }}
            validationSchema={Yup.object({
              firstName: Yup.string()
                .required("Required"),
              lastName: Yup.string()
                .max(20, "Must be 20 characters or less")
                .required("Required"),
              birthDate: Yup.date()
                .required("Required")
                .max(new Date(), "Date of Birth cannot be the future date")
                .min((moment().subtract(100, 'years')), "Please check the format of your date of birth."),
              kinshipId: Yup.number()
                .nullable(true)
                .required("Required"),
              username: Yup.string()
                .nullable(true)
                .test("is-username-match-firstname", "Username must not contain your name", function () {
                  const { firstName, username } = this.parent;
                  const isMatchName = !!username && username.length > 0
                    && !!firstName && firstName.length > 0
                    && username.toLowerCase().includes(firstName.toLowerCase());

                  return !isMatchName;
                }),
            })}
            onSubmit={values => {
              if (child.id) {
                const data = { ...values, id: child.id, countryId: countryId};
                onSave(data);
              } else {
                const data = { ...values, countryId: countryId};
                onSave(data);
              }

              onClose();
            }}
          >
            <Form noValidate>
              <Field
                name={`firstName`}
                component={TextInput}
                label="First name"
                className={classes.marginTop30}
                required={true}
              />
              <Field
                name={`lastName`}
                component={TextInput}
                label="Last name"
                className={classes.marginTop30}
                required={true}
              />
              <Field
                component={EmailInput}
                name="email"
                label="Email"
                className={classes.marginTop30}
                helperText=""
                validate={validateEmail}
                showTooltip
                accessibleDescriptionText="Required email or username"
              />
              <Field
                component={TextInput}
                name="username"
                label="Username"
                className={classes.marginTop30}
                helperText=""
                validate={validateUserName}
                accessibleDescriptionText="Required username or email"
              />
              <Field
                name="birthDate"
                component={DateInput}
                label="Your child's birth date"
                className={classes.marginTop30}
                domesticId={domesticCountry}
                countryId={countryId}
                maxDate={new Date()}
                required={true}
              />
              <Field
                name={`kinshipId`}
                component={Selector}
                label="Their/guardian's relationship"
                className={classes.marginTop30}
                options={guardians}
                required={true}
              />

              <Typography
                variant="h6"
                dangerouslySetInnerHTML={createMarkup(
                  childLabels.childInfoFormConsentText
                )}
                className={classes.parentConsent}
              />

              <div variant="contained" className={classes.buttonGroup}>
                <CustomButton
                  mode={"textButton"}
                  onClick={() => isDataChanged ? setEscapeConfirmDialog(true) : onClose() }
                  label={"Cancel"}
                />
                <CustomButton
                  type="submit"
                  mode={"primary"}
                  label={"Save"}
                />
              </div>
            </Form>
          </Formik>
        </>
      )}
    <PageLeaveModal
      onConfirm={() => { setDataChanged(false); setEscapeConfirmDialog(false); onClose(); }}
      onCancel={() => { setEscapeConfirmDialog(false); }}
      open={isEscapeConfirmDialog}
    />
    </HQModal>
  );
};

export default connectTo(
  state => {
    return {
      contentful: state.contentful
    };
  },
  {},
  ChildModal
);
