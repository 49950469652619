import React from 'react';
import objectPath from 'object-path';
import { makeStyles } from "@material-ui/core/styles";

import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { StyledFormHelper } from '../components/loginSignup/customFormHelper';

const useStyles = makeStyles({
  accessibleDescription: {
    clipPath: "inset(50%)",
    position: "absolute",
    clip: "rect(1px, 1px, 1px, 1px)",
    height: "1px", 
    width: "1px",
    overflow: "hidden",
  },
});

const TextInput = ({ field, form: { errors, touched }, label, placeholder, helperText, Icon, errorIsHtml, maxLength, maxValue, minValue, maxLengthInfo, maxLengthStartingFrom, stepValue, required, setTouchedOnChange, accessibleDescriptionText = null, ...props }) => {
  const classes = useStyles();
  let error = errors[field.name];
  let isTouched = touched[field.name];

  if (field.name.indexOf('[') !== -1 || field.name.indexOf('.') !== -1) {
    const path = field.name.replace('[', '.').replace(']', '').split('.');
    error = objectPath.get(errors, path);
    isTouched = objectPath.get(touched, path);
  }

  const remainingLength = field.value && maxLength && maxLengthInfo ? maxLength - field.value.length : 0;
  const showRemainingLength = maxLengthStartingFrom ? maxLengthStartingFrom > remainingLength : true;

  return (
    <React.Fragment>
    <TextField
      {...props}
      name={field.name}
      onChange={field.onChange}
      error={!!error && (setTouchedOnChange || isTouched)}
      required={!!required}
      onBlur={() => {
        if (typeof(touched) === "object") {
          if (field.name.indexOf('[') === -1 || field.name.indexOf('.') === -1) {
            touched[field.name] = true;
          }
        }
        else {
          touched = true;
        }
      }}
      fullWidth
      placeholder={placeholder}
      label={label}
      helperText={error && isTouched ? (errorIsHtml ? "" : error) : helperText}
      variant={props.variant || "outlined"}
      value={field.value}
      inputProps={{
        step: stepValue ? parseInt(stepValue): null,
        maxLength: maxLength,
        max: maxValue,
        min: minValue,
      }}
      InputProps={{
        placeholder: label || placeholder,
        "aria-describedby": `text-input-help-${field.name}`,
        startAdornment: Icon ? (
          <InputAdornment position="start">
            <Icon />
          </InputAdornment>
        ) : null,
      }}
    />
    {maxLength && field.value && maxLengthInfo && showRemainingLength
    && <StyledFormHelper helperText={`${maxLengthInfo.replace("${value}", remainingLength)}`} isHtml={true} />}
    {error && (isTouched || setTouchedOnChange) &&
      (errorIsHtml
        ? <StyledFormHelper helperText={error} isError={true} isHtml={true} />
        : "")}
    {accessibleDescriptionText && <span id={`text-input-help-${field.name}`} className={classes.accessibleDescription}>
      {accessibleDescriptionText}
    </span>}
    </React.Fragment>
  );
}

export default TextInput;
